<template>
    <section id="about" class="about-section">
      <div class="container">
        <div class="about-content">
          <!-- Lewa kolumna - Lata doświadczenia -->
          <div class="experience-column">
            <div class="years">20</div>
            <div class="years-text">lat doświadczenia</div>
            <img 
              src="@/assets/images/me.jpeg" 
              alt="Moje zdjęcie" 
              class="profile-image"
            >
          </div>

          <!-- Reszta kodu pozostaje bez zmian -->
  
          <!-- Prawa kolumna - Treść -->
          <div class="text-column">
            <h2 class="section-title">O Mnie</h2>
            <blockquote class="quote">
              "Które z moich zdjęć jest moim ulubionym? To, które zrobię jutro."
              <footer>- Imogen Cunningham</footer>
            </blockquote>
  
            <div class="description">
              <p>Fotografią zajmuję się od kiedy pamiętam.</p>
              <p>Wielu ludzi pyta mnie, dlaczego wciąż noszę ze sobą aparat i dlaczego robię zdjęcia. Wówczas odpowiadam <q>a dlaczego Ty jesz?</q></p>
              <p>Można by rzec, że fotografią się karmię. Daje mi to bardzo dużo satysfakcji i jest moim sposobem na życie.</p>
              <p>Choć specjalizuję się w fotografii tanecznej to nie boję się wyzwań związanych z inną dziedziną fotografii.</p>
              <p>Zapraszam do obejrzenia mojej twórczości oraz współpracy.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <style scoped>

@font-face {
  font-family: 'Lunar Bar';
  src: 
    url('@/assets/fonts/luna-bar-regular.woff2') format('woff2'),
    url('@/assets/fonts/luna-bar-regular.woff') format('woff'),
    url('@/assets/fonts/luna-bar-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

  .profile-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 50%;
  margin: 2rem auto 0;
  display: block;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  /*border: 3px solid #000000;*/
}

/* Responsywność */
@media (max-width: 768px) {
  .profile-image {
    max-width: 150px;
    margin-top: 1.5rem;
  }
}
  .about-section {
    padding: 5rem 0;
    background-color: #fff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .about-content {
    display: flex;
    gap: 4rem;
    align-items: flex-start;
  }
  
  .experience-column {
    flex: 0 0 230px;
    text-align: center;
    position: sticky;
    top: 100px;
    font-family: 'Lunar Bar', sans-serif;
  }
  
  .years {
    font-size: 8rem;
    font-weight: 700;
    line-height: 1;
    color: #ff0000;
    font-family: 'Lunar Bar', sans-serif;
  }
  
  .years-text {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 1rem;
    color: #333;
    font-family: 'Lunar Bar', sans-serif;
  }
  
  .text-column {
    flex: 1;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .quote {
    font-size: 1.25rem;
    color: #6c757d;
    border-left: 4px solid #ff0000;
    padding-left: 1.5rem;
    margin: 2rem 0;
  }
  
  .quote footer {
    display: block;
    margin-top: 1rem;
    font-style: italic;
  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  .description p {
    margin-bottom: 1.5rem;
  }
  
  /* Responsywność */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
  
    .experience-column {
      position: static;
      flex: none;
      margin-bottom: 3rem;
    }
  
    .years {
      font-size: 3rem;
    }
    .years-text {
      font-size: 1rem;
    }
    .section-title {
      font-size: 2rem;
    }
  }
  </style>