<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- Lewa kolumna - Mail i telefon -->
      <div class="footer-column left">
        <div class="contact-item">
          <a href="mailto:foto.wawro@gmail.com" class="email">foto.wawro@gmail.com</a>
        </div>
        <div class="contact-item">
          <a href="tel:+48697636021" class="phone">+48 697 636 021</a>
        </div>
      </div>

      <!-- Środkowa kolumna - Ikony i Copyright -->
      <div class="footer-column center">
        <!-- Ikony social -->
        <div class="footer-social-icons">
          <a href="https://www.instagram.com/foto.wawro/" target="_blank" rel="noopener">
            <img src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png" alt="Instagram" class="social-icon">
          </a>
          <a href="https://www.facebook.com/wawrophotographypolska/?locale=pl_PL" target="_blank" rel="noopener">
            <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" class="social-icon">
          </a>
          <a href="https://www.youtube.com/@wlodzimierzwawro" target="_blank" rel="noopener">
            <img src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png" alt="YouTube" class="social-icon">
          </a>
        </div>
        <!-- Copyright -->
        <div class="copyright">
          &copy; {{ new Date().getFullYear() }} Wawro Photography. Wszelkie prawa zastrzeżone.
        </div>
        <!-- Created by -->
        <div class="created-by">
          <a href="https://wawro.ovh" target="_blank" rel="noopener">created by wawro.ovh</a>
        </div>
      </div>

      <!-- Prawa kolumna - Logo -->
      <div class="footer-column right">
        <img 
          src="@/assets/logo_new_b.png" 
          alt="Logo" 
          class="footer-logo"
        >
      </div>
    </div>

    <!-- Przycisk scroll to top -->
    <div class="scroll-top" @click="scrollToTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12 19V5M5 12l7-7 7 7"/>
      </svg>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #000000;
  padding: 2rem 5%;
  border-top: 1px solid #333;
  position: relative;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.footer-column {
  flex: 1;
  padding: 0 15px;
}

.left {
  text-align: left;
  order: 1;
}

.center {
  text-align: center;
  order: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.right {
  text-align: right;
  order: 3;
}

.contact-item {
  margin-bottom: 0.5rem;
}

.email, .phone {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.email:hover, .phone:hover {
  color: #007bff;
}

.footer-social-icons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.footer-social-icons .social-icon {
  width: 25px;
  height: 25px;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
}

.footer-social-icons .social-icon:hover {
  filter: none;
  transform: scale(1.1);
}

.copyright {
  font-size: 0.9rem;
  color: #ffffff;
  margin-top: 0.5rem;
}

.created-by {
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.created-by a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.created-by a:hover {
  color: #007bff;
}

/* Logo w prawym panelu */
.footer-logo {
  height: 40px;
}

/* Przycisk scroll to top */
.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,123,255,0.3);
  z-index: 1000;
}

.scroll-top:hover {
  background-color: #ff0000;
  transform: translateY(-3px);
}

.scroll-top svg {
  width: 24px;
  height: 24px;
  color: white;
}

/* Responsywność */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: static;
  }

  .footer-column {
    width: 100%;
    padding: 10px 0;
    text-align: center !important;
  }

  .left {
    order: 2;
    margin: 1rem 0;
  }

  .center {
    order: 3;
    position: static;
    transform: none;
  }

  .right {
    order: 1;
    margin-bottom: 1rem;
  }

  .scroll-top {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
  
  .scroll-top svg {
    width: 20px;
    height: 20px;
  }
}
</style>
