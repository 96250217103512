<template>
  <section id="offer" class="offer-section">
    <div class="section-header">
      <h2>Oferta</h2>
      <p class="subtitle">Profesjonalne usługi fotograficzne i filmowe</p>
    </div>

    <div class="offer-content">
      <div 
        class="service-item" 
        v-for="(service, index) in services" 
        :key="index" 
        :class="{'reverse': index % 2 !== 0}"
      >
        <div class="service-image">
          <video 
            v-if="service.image.endsWith('.mp4')"
            :src="require(`@/assets/images/offer/${service.image}`)"
            autoplay
            loop
            muted
            playsinline
          ></video>
          <img 
            v-else 
            :src="require(`@/assets/images/offer/${service.image}`)" 
            :alt="service.title"
          >
        </div>
        
        <div class="service-text">
          <h3 class="service-title">{{ service.title }}</h3>
          <div class="service-description" v-html="service.description"></div>
          <div class="mail-button-container">
            <a 
              :href="`mailto:foto.wawro@gmail.com?subject=${encodeURIComponent('Zapytanie ofertowe - ' + service.title)}`" 
              class="mail-button"
            >
              Zapytaj o ofertę
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfferSection',
  data() {
    return {
      services: [
        {
          title: 'Fotografia Nieruchomości',
          image: 'real-estate.jpg',
          description: `Profesjonalne sesje dla rynku nieruchomości:<br>
                        • Zdjęcia wnętrz i architektury<br>
                        • Wirtualne spacery 360°<br>
                        • Optymalizacja pod portale ogłoszeniowe<br>
                        • Retusz i obróbka profesjonalna`
        },
        {
          title: 'Filmy Reklamowe',
          image: 'promo-video.mp4',
          description: `Kreowanie wizerunku marki poprzez film:<br>
                        • Spoty reklamowe i produktowe<br>
                        • Materiały promocyjne dla social media<br>
                        • Montaż z efektami specjalnymi<br>
                        • Storyboard i scenariusze`
        },
        {
          title: 'Wideofilmowanie i Fotografia Ślubna',
          image: 'wedding.jpg',
          description: `Niepowtarzalna dokumentacja wyjątkowych chwil:<br>
                        • Reportaż ślubny (kościelny i cywilny)<br>
                        • Sesje plenerowe i studyjne<br>
                        • Albumy fotograficzne premium<br>
                        • Retusz artystyczny`
        },
        {
          title: 'Filmowanie z Drona',
          image: 'drone.mp4',
          description: `Unikalne ujęcia z powietrza:<br>
                        • Filmowanie eventów i imprez masowych<br>
                        • Ujęcia do filmów reklamowych<br>
                        • Inspekcje budowlane i przemysłowe<br>
                        • Fotografia lotnicza w 8K`
        },
        {
          title: 'Instruktor Lotów Dronem',
          image: 'drone-course.jpg',
          description: `Profesjonalne szkolenia UAV:<br>
                        • Kursy dla początkujących i zaawansowanych<br>
                        • Przygotowanie do certyfikatów UAVO<br>
                        • Warsztaty filmowania z powietrza`
        }
      ]
    }
  }
}
</script>

<style scoped>
.offer-section {
  padding: 5rem 0;
  background-color: #f8f9fa;
}

.section-header {
  text-align: center;
  margin-bottom: 4rem;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60%;
  height: 3px;
  background: linear-gradient(90deg, #ff0000 0%, rgba(0, 0, 0, 0.3) 100%);
}

.subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  letter-spacing: 1px;
}

.offer-content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.service-item {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin: 4rem auto;
  max-width: 1200px;
  padding: 0 20px;
}

.service-item.reverse {
  flex-direction: row-reverse;
}

.service-image {
  flex: 1;
  min-width: 45%;
}

.service-image img,
.service-image video {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.service-text {
  flex: 1;
  padding: 2rem;
}

.service-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
}

.service-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 3px;
  background-color: #ff0000;
}

.service-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
}

.mail-button-container {
  margin-top: 1rem;
}

.mail-button {
  display: inline-block;
  padding: 0.8rem 2rem;
  background: #000000;
  color: #ffffff;
  text-decoration: none;
  border-radius: 30px;
  transition: background 0.3s, transform 0.3s;
  font-weight: 500;
}

.mail-button:hover {
  background: #ff0000;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .service-item {
    flex-direction: column;
    gap: 1.5rem;
    margin: 2rem 0;
    padding: 0 15px;
  }

  .service-item.reverse {
    flex-direction: column;
  }

  .service-image {
    min-width: 100%;
    order: 1;
  }

  .service-image img,
  .service-image video {
    height: 300px;
  }

  .service-text {
    order: 2;
    padding: 0;
    width: 100%;
  }

  .service-title {
    font-size: 1.75rem;
  }

  .section-header h2 {
    font-size: 2rem;
  }
}
</style>