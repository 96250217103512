<template>
  <section id="gallery" class="gallery-section">
    <div class="section-header">
      <h2>Galeria</h2>
      <p class="subtitle">Zobacz moje realizacje</p>
    </div>

    <div class="gallery-tabs">
      <button 
        v-for="(category, index) in categories" 
        :key="index"
        @click="activeTab = index"
        :class="{ active: activeTab === index }"
      >
        {{ category.name }}
      </button>
    </div>

    <div class="gallery-grid">
      <div 
        v-for="(image, imgIndex) in activeCategory.images" 
        :key="imgIndex"
        class="gallery-item"
        :style="`background-image: url(${getImageUrl(image)})`"
        @click="openLightbox(imgIndex)"
      >
        <div class="image-overlay">
          <span>{{ activeCategory.name }}</span>
        </div>
      </div>
    </div>

    <!-- Lightbox Modal -->
    <div v-if="isLightboxOpen" class="lightbox-overlay" @click.self="closeLightbox">
      <div class="lightbox-content">
        <span class="close-button" @click="closeLightbox">&times;</span>
        <img 
          :src="getImageUrl(activeCategory.images[currentImageIndex])" 
          alt="Lightbox Image" 
          class="lightbox-image"
        >
        <div class="lightbox-navigation">
          <button class="prev-button" @click="prevImage">&#10094;</button>
          <button class="next-button" @click="nextImage">&#10095;</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GallerySection',
  data() {
    return {
      activeTab: 0,
      categories: [
        { 
          name: 'Nieruchomości', 
          images: ['real-estate-1.jpg', 'real-estate-2.jpg', 'real-estate-3.jpg', 'real-estate-4.jpg', 'real-estate-5.jpg', 'real-estate-6.jpg','real-estate-7.jpg','real-estate-8.jpg','real-estate-9.jpg']
        },
        { 
          name: 'Portrety', 
          images: ['portrait-1.jpg', 'portrait-2.jpg', 'portrait-3.jpg', 'portrait-4.jpg', 'portrait-5.jpg', 'portrait-6.jpg','portrait-7.jpg','portrait-8.jpg','portrait-9.jpg']
        },
        { 
          name: 'Ślubne', 
          images: ['wedding-1.jpg', 'wedding-2.jpg', 'wedding-3.jpg', 'wedding-4.jpg', 'wedding-5.jpg', 'wedding-6.jpg', 'wedding-7.jpg','wedding-8.jpg','wedding-9.jpg']
        },
        { 
          name: 'Z powietrza', 
          images: ['aerial-1.jpg', 'aerial-2.jpg', 'aerial-3.jpg', 'aerial-4.jpg', 'aerial-5.jpg', 'aerial-6.jpg','aerial-7.jpg','aerial-8.jpg','aerial-9.jpg']
        }
      ],
      isLightboxOpen: false,
      currentImageIndex: 0
    }
  },
  computed: {
    activeCategory() {
      return this.categories[this.activeTab]
    }
  },
  methods: {
    getImageUrl(image) {
      // Pobiera URL zdjęcia z folderu assets. Zmodyfikuj ścieżkę według struktury projektu.
      return require(`@/assets/images/gallery/${this.categories[this.activeTab].name.toLowerCase()}/${image}`)
    },
    openLightbox(index) {
      this.currentImageIndex = index;
      this.isLightboxOpen = true;
    },
    closeLightbox() {
      this.isLightboxOpen = false;
    },
    nextImage() {
      if (this.currentImageIndex < this.activeCategory.images.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.activeCategory.images.length - 1;
      }
    }
  }
}
</script>

<style scoped>
.section-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60%;
  height: 3px;
  background: linear-gradient(90deg, #ff0000 0%, rgba(0, 0, 0, 0.3) 100%);
}

.subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  letter-spacing: 1px;
}

.gallery-section {
  padding: 5rem 0;
  background-color: #fff;
}

.gallery-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.gallery-tabs button {
  padding: 0.8rem 2rem;
  border: none;
  background: #000000;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 500;
}

.gallery-tabs button.active {
  background: #ff0000;
  color: rgb(255, 255, 255);
}

.gallery-tabs button:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-item {
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s;
  overflow: hidden;
  cursor: pointer;
}

.gallery-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;
}

.gallery-item:hover::before {
  opacity: 1;
}

.image-overlay {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
  text-align: center;
  transition: bottom 0.3s;
}

.gallery-item:hover .image-overlay {
  bottom: 0;
}

.image-overlay span {
  font-size: 1.1rem;
  letter-spacing: 1px;
}

/* Lightbox styles */
.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-image {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff0000;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
}

.lightbox-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.prev-button,
.next-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 3rem;
  cursor: pointer;
  padding: 0 20px;
  transition: transform 0.3s;
}

.prev-button:hover,
.next-button:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gallery-item {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: 1fr;
  }
}
</style>
