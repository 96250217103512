<template>
  <section id="pricing" class="pricing-section">
    <div class="section-header">
      <h2>Cennik</h2>
      <p class="subtitle">Przejrzyste pakiety usług</p>
    </div>

    <div class="pricing-container">
      <div class="pricing-grid">
        <!-- Nieruchomości -->
        <div class="price-card">
          <div class="price-header">
            <h3 class="service-title">Nieruchomości</h3>
            <div class="price">od 400 zł</div>
          </div>
          <ul class="price-features">
            <li>10 zdjęć</li>
            <li>Małe mieszkanie</li>
            <li>Niewielka nieruchomość</li>
            <li>Każde kolejne zdjęcie +70 zł/szt</li>
          </ul>
        </div>

        <!-- Fotografia Ślubna -->
        <div class="price-card">
          <div class="price-header">
            <h3 class="service-title">Wideofilmowanie i Fotografia Ślubna</h3>
            <div class="price">od 4500 zł</div>
          </div>
          <ul class="price-features">
            <li class="report">Reportaż ślubny:</li>
            <li>do 12 godzin pracy fotografa i operatora</li>
            <li>Przygotowania, ceremonia, przyjęcie weselne</li>
            <li class="additional">Dodatki:</li>
            <li>Sesja ślubna w innym dniu</li>
            <li>Sesja narzeczeńska przed ślubem</li>
            <li>Teledysk ślubny - zapowiedź filmu</li>
            <li>Podziękowania dla rodziców</li>

          </ul>
        </div>

        <!-- Filmy Reklamowe -->
        <div class="price-card">
          <div class="price-header">
            <h3 class="service-title">Filmy Reklamowe</h3>
            <div class="price"> od 1200 zł</div>
          </div>
          <ul class="price-features">
            <li>30 sek. materiału</li>
            <li>Tło muzyczne</li>
            <li>Video z drona</li>
            <li>Możliwość wydłużenia filmu</li>
          </ul>
        </div>

        <!-- Filmowanie z Drona -->
        <div class="price-card">
          <div class="price-header">
            <h3 class="service-title">Filmowanie z Drona</h3>
            <div class="price"> od 1800 zł</div>
          </div>
          <ul class="price-features">
            <li>60 sek. materiału</li>
            <li>Tło muzyczne</li>
            <li>Profesjonalny montaż</li>
            <li>Możliwość wydłużenia filmu</li>
          </ul>
        </div>

        <!-- Sesje Zdjęciowe -->
        <div class="price-card">
          <div class="price-header">
            <h3 class="service-title">Sesje Zdjęciowe</h3>
            <div class="price">od 600 zł</div>
          </div>
          <ul class="price-features">
            <li>1 godzina sesji</li>
            <li>10 edytowanych zdjęć</li>
            <li>Dodatkowe ujęcia +50 zł/szt</li>
            <li>Plener lub studio</li>
          </ul>
        </div>

        <!-- Wycena Indywidualna -->
        <div class="price-card highlight">
          <div class="price-header">
            <h3 class="service-title">Wycena Indywidualna</h3>
            <div class="price"></div>
          </div>
          <ul class="price-features">
            <li>Więcej zdjęć?</li>
            <li>Więcej filmów?</li>
            <li>Połączenie zdjęć i filmu?</li>
            <li>Stała współpraca?</li>
          </ul>
          <!-- Przycisk otwierający aplikację pocztową -->
          <div class="mail-button-container">
            <a 
              href="mailto:foto.wawro@gmail.com?subject=Zapytanie%20o%20wycen%C4%99" 
              class="cta-button"
            >
              ZAPYTAJ O WYCENĘ
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.report {
  font-weight: bold;
}
.additional {
  font-weight: bold;
}
/* Nagłówek identyczny jak w innych sekcjach */
.section-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60%;
  height: 3px;
  background: linear-gradient(90deg, #ff0000 0%, rgba(0, 0, 0, 0.3) 100%);
}

.subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  letter-spacing: 1px;
}

/* Kontener i siatka */
.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 5rem; /* Dodano dodatkowe odstępy na dole */
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

/* Karty cenowe */
.price-card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s;
}

.price-card:hover {
  transform: translateY(-5px);
}

.price-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.service-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  position: relative;
}

.service-title::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background-color: #ff0000;
}

.price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #000000;
  margin: 1rem 0;
}

.price-features {
  list-style: none;
  padding: 1.5rem 0;
  margin: 1.5rem 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.price-features li {
  padding: 0.5rem 0;
  color: #555;
  text-align: center;
}

/* Przycisk mailowy w karcie Wycena Indywidualna */
.mail-button-container {
  text-align: center;
  margin-top: 1rem;
}

.cta-button {
  display: inline-block;
  padding: 1rem;
  background-color: #ff0000;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
}

.cta-button:hover {
  background-color: #000000;
  transform: scale(1.05);
}

/* Wyróżniona karta */
.highlight {
  border: 2px solid #ff0000;
  position: relative;
  overflow: hidden;
}

.highlight::before {
  content: '';
  position: absolute;
  top: 10px;
  right: -30px;
  background: #ff0000;
  color: white;
  padding: 5px 30px;
  transform: rotate(45deg);
  font-size: 0.8rem;
  letter-spacing: 1px;
}

/* Responsywność */
@media (max-width: 1024px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .pricing-grid {
    grid-template-columns: 1fr;
  }

  .price {
    font-size: 2rem;
  }

  .service-title {
    font-size: 1.3rem;
  }
}
</style>
