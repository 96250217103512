<template>
  <section id="home" class="hero-section">
    <!-- Wideo w tle -->
    <video autoplay loop muted playsinline class="hero-video">
      <source src="@/assets/main_video.mp4" type="video/mp4" />
      Twoja przeglądarka nie obsługuje tagu wideo.
    </video>
    
    <div class="hero-content">
      <h1 class="hero-title">Wawro Photography</h1>
      <button class="cta-button" @click="scrollToOffer">Oferta</button><br>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToOffer() {
      const navbarHeight = 70; // Dostosuj do wysokości Twojego navbara
      const offerSection = document.getElementById('offer');
      
      if (offerSection) {
        const offset = offerSection.offsetTop - navbarHeight;
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Lunar Bar';
  src: 
    url('@/assets/fonts/luna-bar-regular.woff2') format('woff2'),
    url('@/assets/fonts/luna-bar-regular.woff') format('woff'),
    url('@/assets/fonts/luna-bar-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  margin-top: 60px;
}

/* Stylizacja wideo w tle */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  color: white;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 6rem;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Lunar Bar', sans-serif;
  letter-spacing: 3px;
  line-height: 1.1;
  padding: 0 20px;
}

.cta-button {
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #000000;
  transform: scale(1.05) translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
  
  .cta-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 3rem;
  }
  
  .cta-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}
</style>
