<template>
  <nav class="navbar" :class="{ 'hidden': !isNavbarVisible }">
    <div class="logo-container">
      <img src="@/assets/logo_new.png" alt="Logo" class="logo-image">
      <div class="social-icons">
        <a href="https://www.instagram.com/foto.wawro/" target="_blank" rel="noopener">
          <img src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png" alt="Instagram" class="social-icon">
        </a>
        <a href="https://www.facebook.com/wawrophotographypolska/?locale=pl_PL" target="_blank" rel="noopener">
          <img src="https://cdn-icons-png.flaticon.com/512/733/733547.png" alt="Facebook" class="social-icon">
        </a>
        <a href="https://www.youtube.com/@wlodzimierzwawro" target="_blank" rel="noopener">
          <img src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png" alt="YouTube" class="social-icon">
        </a>
      </div>
    </div>

    <!-- Hamburger ikona -->
    <div 
      class="hamburger"
      :class="{ 'active': isMobileMenuOpen }"
      @click="toggleMobileMenu"
    >
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>

    <!-- Menu - widoczne na desktop i mobile -->
    <ul 
      class="nav-links"
      :class="{ 'active': isMobileMenuOpen }"
    >
      <li v-for="(link, index) in links" :key="index">
        <a :href="link.path" @click="closeMobileMenu">{{ link.name }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNavbar',
  data() {
    return {
      isMobileMenuOpen: false,
      isNavbarVisible: true,
      lastScrollPosition: 0,
      scrollThreshold: 50,
      links: [
        { name: 'Strona Główna', path: '#home' },
        { name: 'O Mnie', path: '#about' },
        { name: 'Galeria', path: '#gallery' },
        { name: 'Oferta', path: '#offer' },
        { name: 'Klienci', path: '#clients' },
        { name: 'Cennik', path: '#pricing' }
      ]
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      
      if (currentScrollPosition < 0) return
      
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < this.scrollThreshold) return
      
      this.isNavbarVisible = currentScrollPosition < this.lastScrollPosition || currentScrollPosition === 0
      this.lastScrollPosition = currentScrollPosition
      
      if (!this.isNavbarVisible) {
        this.isMobileMenuOpen = false
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  backdrop-filter: blur(10px);
}

.navbar.hidden {
  transform: translateY(-100%);
  box-shadow: none;
}

/* Ustawienie logo i ikonek obok siebie */
.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo-image {
  height: 70px;
}

.social-icons {
  display: flex;
  gap: 0.5rem;
}

.social-icon {
  width: 25px;
  height: 25px;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
}

.social-icon:hover {
  filter: none;
  transform: scale(1.1);
}

.nav-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  transition: transform 0.3s ease-in-out;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  transition: color 0.3s;
  position: relative;
  padding: 5px 0;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #007bff;
  transition: width 0.3s;
}

.nav-links a:hover::after {
  width: 100%;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .logo-container {
    flex-direction: column;
    align-items: center;
  }

  .nav-links {
    position: fixed;
    top: 70px;
    right: -100%;
    width: 100%;
    height: calc(100vh - 70px);
    background-color: rgba(0, 0, 0, 0.95);
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 3rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
  }

  .nav-links.active {
    right: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
</style>
