<template>
  <div class="app">
    <Navbar />
    <HomeHero />
    <AboutSection />
    <OfferSection />
    <GallerySection />
    <PricingSection />
    <ClientsSection />
    <TheFooter />
    <!-- Tutaj później dodasz inne sekcje -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import HomeHero from '@/components/HomeHero.vue'
import TheFooter from '@/components/TheFooter.vue'
import AboutSection from '@/components/AboutSection.vue'
import ClientsSection from '@/components/ClientsSection.vue'
import OfferSection from './components/OfferSection.vue'
import GallerySection from './components/GallerySection.vue'
import PricingSection from './components/PricingSection.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    HomeHero,
    TheFooter,
    AboutSection,
    ClientsSection,
    OfferSection,
    GallerySection,
    PricingSection
  }
}
</script>

<style>
@import '@/assets/css/global.css';
</style>