<template>
    <section id="clients" class="clients-section">
      <div class="section-header">
      <h2>Współpracowali ze mną</h2>
      <p class="subtitle">Zaufali mi najlepsi</p>
    </div>
      <div class="clients-container">
        <div class="scrolling-logos">
          <div class="logos-track">
            <div 
              v-for="(logo, index) in logos" 
              :key="index" 
              class="logo-item"
            >
              <img 
                :src="require(`@/assets/images/clients/${logo}`)" 
                alt="Logo klienta"
                class="client-logo"
              >
            </div>
            <div 
              v-for="(logo, index) in logos" 
              :key="index + logos.length" 
              class="logo-item"
            >
              <img 
                :src="require(`@/assets/images/clients/${logo}`)" 
                alt="Logo klienta"
                class="client-logo"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ClientsSection',
    data() {
      return {
        logos: ['client1.png', 'client2.png','client3.png','client4.png', 'client5.jpg', 'client6.png', 
        'client7.png', 'client8.png', 'client9.jpeg', 'client10.jpg', 'client11.jpeg', 'client12.jpeg', 'client13.png', 
        'client14.png', 'client15.png', 'client16.png', 'client17.png', 'client18.png', 'client19.png', 'client20.png',
         'client21.png', 'client22.png', 'client23.png', 'client24.png', 'client25.png', 'client26.png','client27.png',
         'client28.png','client29.png','client30.png','client31.png','client32.png','client33.png','client34.png','client35.png','client36.png']
      }
    }
  }
  </script>
  
  <style scoped>
  .section-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60%;
  height: 3px;
  background: linear-gradient(90deg, #ff0000 0%, rgba(0, 0, 0, 0.3) 100%);
}

.subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  letter-spacing: 1px;
}

/* Responsywność */
@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
}
.clients-section {
  padding: 4rem 0;
  background: #fff;
  position: relative;
  z-index: 1;
}

.clients-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.scrolling-logos {
  width: 100%;
}

.logos-track {
  display: flex;
  animation: scroll 50s linear infinite;
  width: max-content;
  will-change: transform;
}

.logo-item {
  flex-shrink: 0;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-logo {
  max-width: 180px;
  max-height: 100px;
  width: auto;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.client-logo:hover {
  filter: grayscale(0);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsywność */
@media (max-width: 768px) {
  .logo-item {
    padding: 0 2rem;
  }

  .client-logo {
    max-width: 120px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
}
</style>